import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import ManageProfile from "../../components/ManageProfile"

const EditProfilePage = () => (
  <Layout>
    <SEO title="Edit Profile" />
    <ManageProfile />
  </Layout>
)

export default EditProfilePage
